import type { FooterLink } from "@/modules/headerFooterFO/Footer/types"

import { useQuery } from "@tanstack/react-query"

import { QUERY_CACHE_TIME, QUERY_STALE_TIME } from "@/modules/headerFooterFO/Footer/constants"
import { FOOTER_LINKS_QUERY_KEY } from "@/modules/headerFooterFO/Footer/services/constants"
import { getFooterLinks } from "@/modules/headerFooterFO/Footer/services/getFooterLinks"
import { useLocale } from "@/modules/locales/useLocale"

export type FooterLinksFetch = {
  dataFooterLinks: FooterLink[]
  isLoadingFooterLinks: boolean
  isSuccessFooterLinks: boolean
  isErrorFooterLinks: boolean
}

export const useFooterLinksFetch = (): FooterLinksFetch => {
  const currentLocale = useLocale()
  const {
    data: dataFooterLinks,
    isPending: isLoadingFooterLinks,
    isSuccess: isSuccessFooterLinks,
    isError: isErrorFooterLinks,
  } = useQuery({
    gcTime: QUERY_CACHE_TIME,

    queryFn: () => {
      return getFooterLinks({
        locale: currentLocale,
      })
    },
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: [FOOTER_LINKS_QUERY_KEY],
    staleTime: QUERY_STALE_TIME,
  })

  return {
    dataFooterLinks: dataFooterLinks || [],
    isErrorFooterLinks,
    isLoadingFooterLinks,
    isSuccessFooterLinks,
  }
}
